import Text from "../../Native/Text/Text";
import Image from "../../Native/Image/Image";
import InviteQuery from '../../Native/InviteQuery/InviteQuery';
import './template3.css'

const Template3: React.FC = () => {
    
  return (
    <div className="template3">
        <div className="template3__header">
            <div className="template3__header_text">
                <p>24 noviembre 2024</p>
            </div>
            <div className="template3__header_image">
                <img src="" alt="" />
                <p>save the date</p>
            </div>
            <div className="template3__header__footer">
                <p>Puebla, Mexico</p>
                <p>Marcos y Daniela</p>
                <p>Salon Puebla</p>
            </div>

        </div>
        <div className="template3__section_2">
            <div className="template3__section_2_text">
                <p>Estamos encantados de compartir este momento especial con ustedes</p>
            </div>
            <div className="template3__section_2_image">
                <img src="" alt="" />
            </div>
        </div>
        <div className="template3__our_parents">
            <img src="" alt="" />
            <div className="template3__our_parents_title">
                <p>Nuestros padres</p>
            </div>
            <div className="template3__our_parents_box">
                <p>De la novia</p>
                <p>Roberto</p>
                <p>Maria belen</p>
            </div>
            <div className="template3__our_parents_box">
                <p>Del novio</p>
                <p>Roberto</p>
                <p>Maria belen</p>
            </div>
        </div>
        <div className="template3__program">
            <div className="template3__program__izq">
                <img src="" alt="" />
            </div>
            <div className="template3__program__der">
                <p>PROGRAMA</p>
                <div className="template3__program_item_box">
                    <div className="template3__program_item">
                        <p>3:00 hrs - Ceremonia religiosa</p>
                    </div>
                    <div className="template3__program_item">
                        <p>3:00 hrs - Ceremonia religiosa</p>
                    </div>
                    <div className="template3__program_item">
                        <p>3:00 hrs - Ceremonia religiosa</p>
                    </div>
                    <div className="template3__program_item">
                        <p>3:00 hrs - Ceremonia religiosa</p>
                    </div>
                    <div className="template3__program_item">
                        <p>3:00 hrs - Ceremonia religiosa</p>
                    </div>
                    <div className="template3__program_item">
                        <p>3:00 hrs - Ceremonia religiosa</p>
                    </div>
                    <div className="template3__program_item">
                        <p>3:00 hrs - Ceremonia religiosa</p>
                    </div>
                    <div className="template3__program_item">
                        <p>3:00 hrs - Ceremonia religiosa</p>
                    </div>
                </div>
            </div>
        </div>
        <div className="template3__event">
            <div className="template3__event__title">
                <p>ceremonia religiosa</p>
                <p>iglesia de santo domingo</p>
            </div>
            <div className="template3__event__image">
                <img src="" alt="" />
            </div>
        </div>
        <div className="template3__event">
            <div className="template3__event__title">
                <p>fiesta</p>
                <p>salon de santo domingo</p>
            </div>
            <div className="template3__event__image">
                <img src="" alt="" />
            </div>
        </div>
        <div className="template3__dressingcode">
            <div className="template3__dressingcode_title">
                <p>Codigo de vestimenta</p>
            </div>
            <div className="template3__dressingcode_images">
                <div className="template3__dressingcode_images_box">
                    <img src="" alt="" />
                    <p>Mujeres no blanco, no verde</p>
                </div>
                <div className="template3__dressingcode_images_box">
                    <img src="" alt="" />
                    <p>Hombres formal</p>
                </div>
            </div>
        </div>
        <div className="template3__mesa_regalos">
            <div className="template3__mesa__regalos_title">
                <p>Mesa de regalos</p>
            </div>
            <div className="template3__mesa_regalos_items_box">
                <div className="template3__mesa_regalos_item">
                    <p>Liverpool: </p>
                    <p>555555</p>
                </div>
                <div className="template3__mesa_regalos_item">
                    <p>Amazon: </p>
                    <p>555555</p>
                </div>
                <div className="template3__mesa_regalos_item">
                    <p>Sears: </p>
                    <p>555555</p>
                </div>
                <div className="template3__mesa_regalos_item">
                    <p>Palacio de hierro: </p>
                    <p>555555</p>
                </div>
            </div>
            <div className="template3__mesa_regalos_img">
                <img src="" alt="" />
            </div>
        </div>
        <div className="template3__galeria">
            <div className="template3__galeria_title">
                <p>Galeria</p>
            </div>
            <div className="template3__galeria__images">
                <div className="template3__galeria__images__img">
                    <img src="" alt="" />
                </div>
                <div className="template3__galeria__images__img">
                    <img src="" alt="" />
                </div>
                <div className="template3__galeria__images__img">
                    <img src="" alt="" />
                </div>
                <div className="template3__galeria__images__img">
                    <img src="" alt="" />
                </div>
                <div className="template3__galeria__images__img">
                    <img src="" alt="" />
                </div>
                <div className="template3__galeria__images__img">
                    <img src="" alt="" />
                </div>
            </div>
        </div>
    </div>
  );
};

export default Template3;
