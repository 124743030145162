import "./builder.css";
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from "./Pages/Builder/Home/Home";
import { Viewer } from "./Pages/Viewer/Viewer";
import Login from "./Pages/Login/login";
import PrivateRoutes from "./helpers/PrivateRoutes";
import Builder from "./Pages/Builder/Builder";
import Templates from "./Pages/Templates/Templates";
import Invitations from "./Pages/Invitations/Invitations";
import { Site } from "./Pages/Site/Site";
import Bum from "./Pages/Bum/Bum";
import { TemplateRenderer } from "./Pages/TemplateRenderer/TemplateRenderer";
import Template3 from "./components/Templates/Template3/Template3";

function App() {
  const { hostname } = window.location;
  const subdomain = hostname.split('.')[0];
  let isAdmin: boolean = subdomain === 'admin';

  return (
    <Router>
      <Routes>
        {/* Rutas para admin.site.com */}
        { isAdmin ? (
          <>
            <Route element={<PrivateRoutes />}>
              <Route path="/home" element={<Builder />} />
              <Route path="/builder" element={<Builder />} />
              <Route path="/viewer" element={<Viewer />} />
              <Route path="/templates" element={<Templates />} />
              <Route path="/invitations" element={<Invitations />} />
              <Route path="*" element={<Home />} />
            </Route>        
            {/* Rutas públicas */}
            <Route path="/login" element={<Login />} />
            <Route path="/bum" element={<Bum />} />
            <Route path="/templateRenderer" element={<TemplateRenderer />} />
            <Route path="/template3" element={<Template3 />} />
          </>
        ) : (
          // Rutas para site.com
          <>
            <Route path="/site" element={<Site />} />
            {/* Redirect to Site for any other route */}
            <Route path="*" element={<Site />} />
          </>
        )}

      </Routes>
    </Router>
  );
}

export default App;
