import React from 'react';
import './Toast.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const iconMap: any = {
  success: 'fa-check-circle',
  error: 'fa-exclamation-circle',
  warning: 'fa-exclamation-triangle',
};

const Toast = ({ message, type }: any) => {
  return (
    <div className={`toast ${type}`}>
      <i className={`fas ${iconMap[type]} toast-icon`}></i>
      <p>{message}</p>
    </div>
  );
};

export default Toast;
