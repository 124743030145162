import { useCookies } from 'react-cookie';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, fetchContent } from '../../store/actions/dataSlice';
import { useEffect, useState } from 'react';
import axios from 'axios';
import './login.css';
import { useNavigate } from 'react-router-dom';
import { API_URL } from '../../helpers/api';
import { sanitizeInput } from '../../helpers/helpers';
import { useToast } from '../../components/Native/Toast/ToastContext';

const Login = () => {

    const addToast = useToast();


    const  contents  = useSelector((state: any) => state.content.contents);

    const [cookies, setCookie] = useCookies(['token']);
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');

    const localStorage = window.localStorage;

    const dispatch = useDispatch<AppDispatch>();
    const navigate = useNavigate();


    useEffect(() => {
        if (contents.url) {
          navigate('/'); // Cambia '/some-path' por la ruta deseada
        }
      }, [contents]);

    const handleSubmit = (e: any) => {
        e.preventDefault();
        let user = sanitizeInput(username)
        let pass = sanitizeInput(password)
        const data = JSON.stringify({username: user, password: pass });
        axios.post(`${API_URL}login`, data, {
            headers: {
                'Content-Type': 'application/json',
            },
        })
        .then((response) => {
            let date = new Date();
            let idBoda = response.data.idBoda;
            let idSitio = response.data.idSitio;
            date.setHours(date.getHours() + 8);
            setCookie('token', 'asd', { path: '/', expires: date }); // TODO: add real JWT later
            localStorage.setItem('idBoda', idBoda);
            localStorage.setItem('idSitio', idSitio);
            localStorage.setItem('weddingUser', username);

            dispatch(fetchContent());
            //navigate('/'); // Redirect to success page after action

        })
        .catch((error) => {
            let errorMessage = 'Error desconocido'; // Mensaje de error predeterminado
            if (error.response) {
                // Si hay una respuesta de error del servidor
                errorMessage = error.response.data.message; // Asigna el mensaje de error proporcionado por el servidor
            } else if (error.request) {
                // Si la solicitud fue realizada pero no se recibió respuesta
                errorMessage = 'No se pudo conectar al servidor';
            } else {
                // Si ocurrió un error durante la configuración de la solicitud
                errorMessage = error.message;
            }
            addToast('Error al iniciar sesión.', 'error');
        });
    };

    return (
        <div className="body-login">
            <div className="login-form-container">
                <h1 className="login-form-title">Iniciar sesión</h1>
                <form onSubmit={handleSubmit}>
                    <input
                        type="text"
                        name="username"
                        onChange={(e) => setUsername(e.target.value)}
                        placeholder="Usuario"
                        className="login-form-input"
                    />
                    <input
                        type="password"
                        name="password"
                        onChange={(e) => setPassword(e.target.value)}
                        placeholder="Contraseña"
                        className="login-form-input"
                    />
                    <input
                        type="submit"
                        value="Entrar"
                        className="login-form-button"
                    />
                </form>
            </div>
        </div>
    );
};

export default Login;
