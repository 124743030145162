// TextModal.tsx
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import {
  ModalProps,
} from "../../../store/actions/dataSlice";
import Modal from "../../Native/Modal/Modal";
import ColorPicker from "../../Native/ColorPicker/ColorPicker";


const TextModal: React.FC<ModalProps> = ({ element }) => {
  const selectedElement = useSelector((state: { content: any }) => state.content.selectedElement);

  const [data, setData] = useState<string | undefined>(selectedElement.data);
  const [color, setColor] = useState<string | undefined>(selectedElement.color);


  // Crea una referencia para el input
  const inputRef: any = useRef(null);
  
  useEffect(() => {
    setData(selectedElement.data);
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, [selectedElement.data]);

  const handleText = (e: React.ChangeEvent<HTMLInputElement>) => {
    setData(e.target.value);
  };


  const handleColor = (e: React.ChangeEvent<HTMLInputElement>) => {
    setColor(e.target.value);
  };

  return (
    <Modal data={data} element={element} color={color} >
      <input type="text" ref={inputRef}  value={data} onChange={handleText} />
      <ColorPicker value={color} onChange={handleColor} />
    </Modal>
  );
};

export default TextModal;
