import { useCookies } from 'react-cookie';
import { Outlet, Navigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom'; // Import for path checking
import NavbarBuilder from '../components/builder/NavbarBuilder/NavbarBuilder';
import Sidebar from '../components/builder/Sidebar/Sidebar';
import './privateRoutes.css';

const ProtectedNavigation = () => {
  const [cookies] = useCookies(['token']);
  const localStorage = window.localStorage;
  const idBoda = localStorage.getItem("idBoda");
  const isAuthenticated = cookies.token && idBoda != null;


  // Get current pathname for conditional rendering
  const location = useLocation();
  const isBuilderOrViewer = location.pathname.includes('/builder') || location.pathname.includes('/viewer');
  
  if (!isAuthenticated) {
    return <Navigate to="/login" />;
  }


  return (
    <>
    

  <div className="content-wrapper">
      <Sidebar />
      <div className="builder-wrapper">
        {isBuilderOrViewer && <NavbarBuilder /> }
        <Outlet />
      </div>

  </div>

    </>
  );
};

const PrivateRoutes = () => {
  return <ProtectedNavigation />;
};

export default PrivateRoutes;
