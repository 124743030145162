// TextModal.tsx
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { ModalProps } from "../../../store/actions/dataSlice";
import Modal from "../../Native/Modal/Modal";
import './styles-image-modal.css';
import { API_URL } from "../../../helpers/api";
import { useToast } from "../../Native/Toast/ToastContext";

const ImageModal: React.FC<ModalProps> = ({ element }) => {
  const selectedElement = useSelector((state: { content: any }) => state.content.selectedElement);
  const addToast = useToast();

  const [data, setData] = useState<string | ''>(selectedElement.data);
  const [image, setImage] = useState<string | Blob>('');
  const [formData, setFormData] = useState<FormData>();

  let idBoda: any = localStorage.getItem('idBoda');
  const [url, setUrl] = useState(API_URL + 'images/' + idBoda + '/' + data);

  // Ref para el input file
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    setData(selectedElement.data);
  }, [selectedElement.data]);

  const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];

    if (file) {
      // Validación de tipo de archivo
      const validImageTypes = ['image/jpeg', 'image/png'];
      if (!validImageTypes.includes(file.type)) {
        addToast('Por favor, selecciona un archivo de imagen válido (JPEG, PNG).', 'error');
        if (fileInputRef.current) fileInputRef.current.value = ''; // Limpiar input
        return;
      }

      // Validación de tamaño de archivo (ejemplo: 12MB)
      const maxSizeInBytes = 12 * 1024 * 1024;
      if (file.size > maxSizeInBytes) {
        addToast('El tamaño del archivo es demasiado grande. El tamaño máximo permitido es de 12MB.', 'error');
        if (fileInputRef.current) fileInputRef.current.value = ''; // Limpiar input
        return;
      }

      const imageUrl = URL.createObjectURL(file);
      setImage(file);
      setUrl(imageUrl);

      const formData = new FormData();
      formData.append('image', file);
      formData.append('idBoda', idBoda);
      formData.append('oldImage', data);

      setFormData(formData);
    }
  };

  return (
    <Modal data={data} element={element} image={formData}>
      <img src={url} alt="" />
      <form encType="multipart/form-data">
        <input type="file" ref={fileInputRef} onChange={handleImageChange} />
      </form>
    </Modal>
  );
};

export default ImageModal;
