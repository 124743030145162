import { useLocation, useNavigate } from 'react-router-dom';
import './sidebar.css'
import { useCookies } from 'react-cookie';
import { useSelector, useDispatch } from 'react-redux';
import { CleanContents } from '../../../store/actions/dataSlice';
const Sidebar = () => {

    const [, , removeCookie] = useCookies(['token']);
    let state = useSelector((state: any) => state.content.contents);
    let username = localStorage.getItem('weddingUser');
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();

      // Verifica si el usuario está en la ruta "/builder"
  const isBuilderPage = location.pathname === '/builder';
  const isPreviewPage = location.pathname === '/viewer';

    const logout = () => {
        removeCookie('token');
        localStorage.removeItem('idBoda');
        localStorage.removeItem('dataBoda');
        localStorage.removeItem('weddingUser');
        localStorage.removeItem('idSitio');
        dispatch(CleanContents());
    };

    const isActive = (path: any) => location.pathname === path;
    return ( 
        <div className={`sidebar ${isBuilderPage ? 'edit-mode' : isPreviewPage ? 'edit-mode' : ''}`}>
            <div className="wrap">
                <p className='sidebar-title' onClick={() => navigate('/builder')}>miboda.xyz</p>
                <a onClick={() => navigate('/builder') }><i className="fa-regular fa-gear"></i> Editar sitio</a>
                <a onClick={() => navigate('/invitations') }><i className="fa-light fa-pencil"></i> Invitaciones</a>
                <a onClick={() => navigate('/templates') }><i className="fa-regular fa-palette"></i> Cambiar diseño</a>
                <div className="logout-section">
                    <p className='logout-section__name'><i className="fa-regular fa-user"></i> {username}</p>
                    <a onClick={logout}><i className="fa-sharp fa-regular fa-arrow-right-from-bracket"></i> Log out</a>
                </div>
            </div>
            {isBuilderPage &&
              <div className="mode-indicator mode-indicator-responsive">
                <span className="mode-text"><i className="fa-regular fa-pen-to-square"></i> Modo Edición</span>
              </div>
            }

            {isPreviewPage &&
              <div className="mode-indicator mode-indicator-responsive preview">
                <span className="mode-text"><i className="fa-sharp fa-light fa-eye"></i> Modo Vista Previa</span>
              </div>
            }
            <div className={`wrap-responsive ${isBuilderPage ? 'wrap-edit-mode' : isPreviewPage ? 'wrap-edit-mode' : ''}`}>
                <ul className='wrap-responsive-ul'>
                <div className="wrap-responsive-ul-items">
                    <li onClick={() => navigate('/invitations') }>
                        <i className={`fa-envelope ${isActive('/invitations') ? 'fa-regular sidebar-active' : 'fa-light'}`}></i>
                    </li>
                    <li onClick={() => navigate('/viewer') }>
                        <i className={`fa-eye ${isActive('/viewer') ? 'fa-regular sidebar-active' : 'fa-light'}`}></i>
                    </li>
                    <li onClick={() => navigate('/builder') }>
                        <i className={`fa-pen-to-square ${isActive('/builder') ? 'fa-regular sidebar-active' : 'fa-light'}`}></i>
                    </li>
                    <li onClick={() => navigate('/templates') }>
                        <i className={`fa-palette ${isActive('/templates') ? 'fa-regular sidebar-active' : 'fa-light'}`}></i>
                    </li>
                    <li onClick={() => logout() }>
                        <i className={`fa-right-from-bracket ${isActive('/logout') ? 'fa-regular' : 'fa-light'}`}></i>
                    </li>
                </div>

                </ul>
            </div>
        </div>
     );
}
 
export default Sidebar;