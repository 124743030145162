import { useDispatch } from 'react-redux';
import './templates-styles.css';
import { AppDispatch, fetchContent, ReplaceTemplate } from '../../store/actions/dataSlice';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { API_URL } from '../../helpers/api';
import { useToast } from '../../components/Native/Toast/ToastContext';

const Templates = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch<AppDispatch>();
    const addToast = useToast();


    const handleClickTemplate = (templateId: any) => {
      if (window.confirm(`¿Estás seguro/a de reemplazar el diseño? Todas las imágenes y textos modificados se perderán.`)) {

        const idBoda = localStorage.getItem('idBoda')

        const obj = JSON.stringify({
          "idBoda": idBoda,
          "idTemplate":templateId,
          "url": "www.bobadilla.com"
      });
        axios.post(`${API_URL}updateTemplate`, obj, {
          headers: {
            "Content-Type": "application/json",
          },
        })
      .then(() => {
        dispatch(fetchContent())
        addToast('Diseño cambiado', 'success');
        //navigate('/builder');
      })
      .catch(() => {
        addToast('Hubo un error', 'error');
      });
          //dispatch(ReplaceTemplate({ template: templateObj }));W
      }
    };

    const handlePreviewTemplate = (templateId: any) => {
      window.open('/TemplateRenderer?idTemplate=' + templateId, '_blank');
    }

    return (
        <>
            <div className='max-container'>
                <div className="header-templates">
                    <h1>Diseños de invitaciones</h1>
                </div>
                <div className="templates-container">
                    <div className="templates-box">
                        <div className="template">
                            <div className="background-image">
                                <img src="./1.png" alt="" />
                            </div>
                            <div className="template-data">
                                <p className='template-data-item'>Diseño elegante 1</p>
                                <button className='template-data-item btn-preview' onClick={() => handlePreviewTemplate(1)}>Ver diseño</button>
                                <button className='template-data-item btn-replace' onClick={() => handleClickTemplate(1)}>Reemplazar diseño</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Templates;
