// dataSlice.ts
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import axios from "axios";
import { store } from "./store";
import { handleSave, sanitizeInput } from "../../helpers/helpers";
import { API_URL } from "../../helpers/api";
import { useToast } from "../../components/Native/Toast/ToastContext";


const emptyElement: any = {
  id: "",
  type: "",
  data: "",
};

const emptySection: any = {
  id: "",
  type: "",
  subtype: "SECTION",
  backgroundColor: "",
  template: "",
  name: "",
  meta: {}, // Dejando meta como any
};


const initialState: any = {
  contents: {},
  isLoading: false,
  error: null,
  showSelectedElement: false,
  selectedElement: null,
  selectedSection: null,
  show: false,
  reload: 0,
  username: ''
};

export const fetchContent = createAsyncThunk(
  'content/fetchContent',
  async () => {
    const localStorage = window.localStorage;

    // en prod vamos a cambiar esto para que agarre el id de cada boda dado un hashmap o algun registro que tengamos
    const idBoda = localStorage.getItem("idBoda");
    const res = await axios(`${API_URL}data?idBoda=`+idBoda)
    const data = await res.data
    return data
  }
)


export const dataSlice = createSlice({
  name: "content",
  initialState: initialState,
  reducers: {
    updateData: (
      state,
      action: PayloadAction<{ id: string; idSection: string; data: string, addToast: any}>,
    ) => {
      const { id, idSection, data, addToast } = action.payload;
      const elementToUpdate = selectElementById(state, idSection, id);
      if (elementToUpdate) {
        elementToUpdate.data = sanitizeInput(data)
        handleSave(state.contents, addToast) 
      }
    },
    updateTextColor: (
      state,
      action: PayloadAction<{ id: string; idSection: string; color: string }>,
    ) => {
      const { id, idSection, color } = action.payload;
      const elementToUpdate = selectElementById(state, idSection, id);
      if (elementToUpdate) {
        elementToUpdate.color = color;
      }
    },
    updateSelectedElement: (
      state,
      action: PayloadAction<{ element: any; idSection: string }>,
    ) => {
      const { element, idSection } = action.payload;
      state.selectedElement = element;
      state.selectedSection = idSection;
    },
    cleanSelectedElement: (state) => {
      state.selectedElement = emptyElement;
    },
    updateShowSelectedElement: (
      state,
      action: PayloadAction<{ show: boolean }>,
    ) => {
      const { show } = action.payload;
      state.showSelectedElement = show;
    },
    AddSection: (state, action: PayloadAction<{ idSection: string }>) => {
      const { idSection } = action.payload;

      const nuevaSeccion = {
        id: Date.now().toString(),
        type: "SECTION",
        subtype: "DESCRIPTION",
        backgroundColor: null,
        template: "template_1",
        name: "Description1",
        meta: {
          header: {
            id: "1",
            data: "Una vez más el amor triunfó. Acompañanos a celebrar",
            type: "TEXT",
          },
          subtitle: { id: "2", data: "subtitulo", type: "TEXT" },
          footer: {
            id: "3",
            data: "por nuestra parte, hemos....",
            type: "TEXT",
          },
          image: {
            id: "4",
            data: "image.jpg",
            type: "IMAGE",
          },
        },
      }
      // Encontrar el índice de la sección objetivo
      let targetIndex = 0;
      for (const key in state.contents?.body?.sections) {
        if (state.contents?.body?.sections[key].id === idSection) {
          targetIndex = parseInt(key) + 1 ;
          break;
        }
      }

      // Insertar la nueva sección
      const tempArray = Object.values(state.contents?.body?.sections);
      tempArray.splice(targetIndex, 0, nuevaSeccion);
      state.contents.body.sections = Object.fromEntries(tempArray.map((section, index) => [index, section]));
    },
    DeleteSection: (state, action: PayloadAction<{ idSection: string }>) => {
      const { idSection } = action.payload;
      // Encontrar el índice de la sección a borrar
      let targetIndex = 0;
      for (const key in state.contents?.body?.sections) {
        if (state.contents?.body?.sections[key].id === idSection) {
          targetIndex = parseInt(key);
          break;
        }
      }

      // Borrar la sección
      const tempArray = Object.values(state.contents?.body?.sections);
      tempArray.splice(targetIndex, 1);
      state.contents.body.sections = Object.fromEntries(tempArray.map((section, index) => [index, section]));
    },
    CleanContents: (state) => {
      state.contents = {};
    },
    ReplaceTemplate: (
      state,
      action: PayloadAction<{ template: any; }>,
    ) => {
      const { template } = action.payload;
      state.contents.body.sections[0] = template;
    },
    GetContentsLocalStorage: (state) => {
      const data = window.localStorage.getItem('dataBoda') || ''
      state.contents = JSON.parse(data);
    },
    setShowTrue: (state) => {
      state.show = true;

    },
    setShowFalse: (state) => {
      state.show = false;
    },
    setReload: (state) => {
      state.reload = state.reload + 1;
      window.localStorage.setItem('dataBoda', JSON.stringify(state.contents))
    }
  },

  extraReducers: (builder) => {
    builder.addCase(fetchContent.pending, (state) => {
      state.isLoading = false
    })
    builder.addCase(fetchContent.fulfilled, (state, action) => {
      state.isLoading = true
      state.contents = action.payload
      window.localStorage.setItem('dataBoda', JSON.stringify(action.payload))
    })
    builder.addCase(fetchContent.rejected, (state, action) => {
      state.isLoading = false
      state.error = action.error.message
    })
  },
});

export interface ModalProps {
  id?: string;
  element: any
}

/*
 * Selector para obtener una seccion por su ID
 */
export const selectSectionById = (state: any, id: string): any => {
  const sections = Object.values(state.contents?.body?.sections) as any[];
  return sections.find((section: any) => section.id === id) || emptySection;
};

// Selector para obtener una seccion por su ID
export const selectElementById = (
  state: any,
  idSection: string,
  idElement: string,
): any => {
  const section: any = selectSectionById(state, idSection).meta;
  const element = Object.values(section).find(
    (element: any) => element?.id === idElement,
  );
  return element || emptyElement;
};



export const {
  AddSection,
  DeleteSection,
  updateData,
  updateSelectedElement,
  cleanSelectedElement,
  CleanContents,
  ReplaceTemplate,
  GetContentsLocalStorage,
  updateShowSelectedElement,
  setShowTrue, setShowFalse,
  setReload,
  updateTextColor,
} = dataSlice.actions;
export type AppDispatch = typeof store.dispatch
export default dataSlice.reducer;
